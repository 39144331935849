<template>
  <div>
    <div style="margin-top: 8rem; cursor: pointer;" @click="moveToSure">
      <img
        style="width: 100%;"
        src="../assets/home/promo-banner.gif"
        alt="promo"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PromosGif",
  components: {},
  data() {
    return {};
  },

  mounted() {},
  methods: {
    moveToSure() {
      this.$router
        .push({
          path: `/sure`
        })
        .catch(() => {});
    }
  }
};
</script>
