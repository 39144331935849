<template>
  <div class="failed" id="notification">
    <div>
      <!-- <div>Error: Entry(ies) failed!</div> -->
      <div class="error-info">{{ apiFailed }}</div>
    </div>
    <!-- <div class="input-group">
            <img src="@/assets/images/cancel-icon.svg" alt=""/>
        </div> -->
  </div>
</template>

<script>
export default {
  name: "NotificationFailed",
  components: {},
  data() {
    return {};
  },
  computed: {
    apiFailed() {
      return this.$store.state.apiFailed;
    }
  },
  methods: {}
};
</script>

<style scoped>
.failed {
  text-align: left;
  /* background-color: #E10000; */
  background: #fff;
  /* background-color: #ff9c2a; */
  border-radius: 5px 5px;
  width: 100%;
  padding: 0 5%;
  /* color: #fff; */
  color: #e10000;
  border: 1px solid #e10000;
  font-family: "Roboto-Light";
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-bottom: 5px;
  font-size: 14px;
}
.error-info {
  font-size: 14px;
  text-transform: capitalize;
}
.input-group {
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  /* background: #fff; */
  background: #e10000;
}
@media (min-width: 425px) {
  .failed {
    padding: 0 25px;
  }
}
@media (min-width: 768px) {
  .failed {
    padding: 0 25px;
    font-size: 16px;
  }
}
</style>
