import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import pagination from "./pagination.vue";
// import Vuelidate from "vuelidate";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "vue-popperjs/dist/vue-popper.css";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "font-awesome/css/font-awesome.min.css";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import moment from "moment";
import VueSocialSharing from "vue-social-sharing";
import VueCookies from "vue-cookies";
// import VueCryptojs from "vue-cryptojs";
// import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion";

Vue.prototype.moment = moment;
Vue.use(VueCookies);
// import VueAnalytics from 'vue-analytics';

// Vue.use(VueCryptojs);

// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component(
  "VueFontawesome",
  require("vue-fontawesome-icon/VueFontawesome.vue").default
);

// Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta);

// Vue.use(VueGtag, {
//   config: { id: "G-YY48MD7RG1" }
// });
Vue.use(VueGtag, {
  config: { id: "G-6XSTDBMRTT" }
});

Vue.use(VueSocialSharing);

// Vue.component("BadgerAccordion", BadgerAccordion);
// Vue.component("BadgerAccordionItem", BadgerAccordionItem);

// Vue.$cookies.set('theme','default');
// Vue.$cookies.set('hover-time','1s');

// Vue.$cookies.config('shelta-c');

// Vue.use(VueAnalytics, {
//   id: 'G-L7NKTE67L7',
// VueCookies.config('7d')//   router
// })

// Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

export const vm = new Vue();
export const EventBus = new Vue();
