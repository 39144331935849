<template>
  <div class="nav_holder">
    <div class="blue_nav">
      <div class="blue_child">
        <p>
          <!-- <section class="stay-in-touch d-none d-md-block">Stay in touch with us</section> -->
          <a href="mailto:hello@myshelta.com"
            ><img src="../assets/images/envelop-white.svg" alt="email" />
            hello@myshelta.com</a
          >
        </p>
        <p>
          <!-- <section class="reach-out d-none d-md-block">Reach out to us on</section> -->
          <a href="https://api.whatsapp.com/send?phone=2348061796909"
            ><img src="../assets/images/whatsApp-white.svg" alt="whatsapp" />
            (+234) 806 179 6909</a
          >
        </p>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg">
      <div class="navbar-outer" :class="{ open: isNavOpen }">
        <a class="navbar-brand" @click="routeTo()">
          <img src="../assets/images/logo-yellow.svg" alt="logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleNav"
        >
          <!-- <span class="navbar-toggler-icon"></span> -->
          <img
            src="../assets/images/hamburger_orange.svg"
            alt="menu"
            id="open-menu"
          />
          <img
            src="../assets/images/close_icon_blue.svg"
            class="collapse show"
            alt="close menu"
            id="close-menu"
          />
        </button>
      </div>

      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent"
      >
        <div class=" my-2 my-lg-0">
          <ul class="navbar-nav m-auto">
            <li class="nav-item active">
              <a class="nav-link">
                <router-link to="/shelta">Home</router-link>
              </a>
            </li>
            <div class="dropdown-divider"></div>
            <!-- <li class="nav-item active">
              <a class="nav-link">
                <router-link to="/sure">SURE 2023</router-link>
              </a>
            </li> -->
            <div class="dropdown-divider"></div>

            <li class="nav-item">
              <a class="nav-link" @click="aboutus">
                <router-link to="/aboutus">About</router-link>
              </a>
            </li>
            <div class="dropdown-divider"></div>

            <!-- <li class="nav-item">
              <a class="nav-link" @click="safeLink">
                <router-link to="/safe">Safe</router-link>
              </a>
            </li> -->

            <li class="nav-item">
              <a class="nav-link">
                <router-link to="/blogs">Blog</router-link>
              </a>
            </li>
            <div class="dropdown-divider"></div>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
                <img
                  src="../assets/images/chevron-right-small.svg"
                  alt="open dropdown button"
                />
              </a>
              <div
                class="dropdown-menu"
                id="bg_color"
                aria-labelledby="navbarDropdown"
              >
                <div class="dropdown-item">
                  <a href="https://sales.myshelta.com/" class="p-0">
                    Buy
                  </a>
                </div>
                <div class="dropdown-item">
                  <router-link to="/safe">Safe</router-link>
                </div>
                <div class="dropdown-item">
                  <router-link to="/rentpayer">Rent Payer</router-link>
                </div>

                <div class="dropdown-item">
                  <router-link to="/propertylist">Property List</router-link>
                </div>
                <div class="dropdown-item">
                  <a
                    class=" p-0"
                    href="https://smart.myshelta.com/"
                    target="_blank"
                  >
                    <!-- <router-link  to="https://smart.myshelta.com/" target="_blank">Smart</router-link> -->
                    Smart Associate
                  </a>
                </div>
              </div>
            </li>
            <div class="dropdown-divider"></div>

            <li class="nav-item dropdown d-none">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                How it works

                <img
                  src="../assets/images/chevron-right-small.svg"
                  alt="open dropdown button"
                />
              </a>
              <div
                class="dropdown-menu"
                id="bg_color"
                aria-labelledby="navbarDropdown"
              >
                <a class="dropdown-item">
                  <router-link to="/tenant">Tenant</router-link>
                </a>
                <a class="dropdown-item">
                  <router-link to="/landlord">Landlord</router-link>
                </a>
                <a class="dropdown-item" href="#">Facility Management</a>
              </div>
            </li>
            <div class="dropdown-divider"></div>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Support
                <img
                  src="../assets/images/chevron-right-small.svg"
                  alt="open dropdown button"
                />
              </a>
              <div
                class="dropdown-menu"
                id="bg_color"
                aria-labelledby="navbarDropdown"
              >
                <div class="dropdown-item">
                  <router-link to="/contactus">Contact us</router-link>
                </div>

                <div class="dropdown-item">
                  <router-link to="/faq">
                    FAQs
                  </router-link>
                </div>
              </div>
            </li>

            <!-- <li class="nav-item">
              <a class="nav-link" href="https://myshelta.com/careers/"
                >Careers</a
              >
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" v-if="!loggedIn">Dashboard</a>
            </li> -->
            <li class="register_btn" v-if="!loggedIn">
              <!-- data-toggle="modal"
                data-target="#user_type" -->
              <button
                class="btn secondary-btn"
                @click.prevent="userType"
              >
                <p class="mb-0">Dashboard</p>
              </button>
              <button class="btn primary-btn" @click="logOut()">
                <router-link to="/login">
                  Logout
                </router-link>
              </button>
            </li>
            <li class="register_btn" v-if="loggedIn">
              <button class="btn secondary-btn">
                <router-link to="/login">
                  Login
                </router-link>
              </button>
              <button class="btn primary-btn">
                <router-link to="signup">
                  Sign up
                </router-link>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- User Type modal -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="user_type"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="user__switch">
            <span
              @click.prevent="switchUser('landlord')"
              :class="isActive === 'landlord' ? 'activeUser' : 'inactive'"
              >Landlord</span
            >
            <span
              @click.prevent="switchUser('tenant')"
              :class="isActive === 'tenant' ? 'activeUser' : 'inactive'"
              >Tenant</span
            >
          </div>
          <!-- <div class="custom-control custom-checkbox form-check">
              <input
                @change="logger($event)"
                type="checkbox"
                class="custom-control-input"
                id="dont"
                name="dn"
                true-value="noremenber"
                false-value="remember"
                v-model="loggedIn"
              />
              <label class="custom-control-label checkboxlabel" for="dont"
                >Don’t show this again</label
              >
            </div> -->

          <div class="modal__btn">
            <button @click="routeLogger()" class="btn">CONTINUE</button>
          </div>
        </div>
      </div>
    </div>
    <!-- User Type modal -->

    <!-- This is also in the navigation component -->
    <inFoPopup />
  </div>
</template>

<script>
import $ from "jquery";
import inFoPopup from "../components/InfoPopup.vue";
export default {
  name: "Header",
  components: {
    inFoPopup
  },
  data() {
    return {
      loggedIn: "",
      isActive: "landlord",
      isNavOpen: false
    };
  },
  mounted() {
    if (localStorage.getItem("shelta")) {
      this.loggedIn = false;
    } else {
      this.loggedIn = true;
    }
    if (localStorage.getItem("shelta-loger")) {
      $("#user_type").modal("hide");
      // if (localStorage.getItem("shelta-loger") == "Landlord") {
      //   // return this.$router.push("/dashboard/landlord");
      // }
      // if (localStorage.getItem("shelta-loger") == "Tenant") {
      //   // return this.$router.push("/dashboard/tenat");
      // }
    }
    setTimeout(() => {
      if (!localStorage.getItem("popup") && this.$route.name != "ContactUs" && this.$route.name != "Sure") {
        this.infoPopup();
      }
    }, 60000);
  },
  methods: {
    // This is also in the navigation component
    infoPopup() {
      this.$root.$refs.F.openInfo();
    },
    userType() {
      if (localStorage.getItem("shelta-loger") == "Landlord") {
        return this.$router.push("/dashboard/landlord");
      }
      if (localStorage.getItem("shelta-loger") == "Tenant") {
        return this.$router.push("/dashboard/tenant");
      }
      // if (!localStorage.getItem("shelta-loger")) {
      //   $("#user_type").modal("show");
      // }
    },
    aboutus() {
      this.$gtag.event("home-link-initial-click", {
        event_category: "about us navigation documentaion clicks",
        event_label: "About Us Clicked",
        value: 1
      });
    },
    safeLink() {
      this.$gtag.event("home-link-initial-click", {
        event_category: "safe navigation documentaion clicks",
        event_label: "Safe Button Clicked",
        value: 1
      });
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    logOut: function() {
      if (localStorage.getItem("popup")) {
        window.localStorage.clear();
        localStorage.setItem("popup", "NO");
      } else {
        window.localStorage.clear();
      }
      this.$cookies.remove("shelta-c");
      location.reload();
      // this.$router.push("/login");
      this.$router
          .push({
            path: `/login`
          })
          .catch(() => {});
    },
    routeTo: function() {
      this.$router.push("/shelta");
    },
    switchUser: function(user) {
      this.isActive = user;
    },
    routeLogger: function() {
      if (this.isActive === "landlord") {
        localStorage.setItem("shelta-loger", "Landlord");
        $("#user_type").modal("hide");
        this.$router.push("/dashboard/landlord");
      }
      if (this.isActive === "tenant") {
        localStorage.setItem("shelta-loger", "Tenant");
        $("#user_type").modal("hide");
        this.$router.push("/dashboard/tenant");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:root {
  ---mobile-nav-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
}

$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$gray: #f6f6f6;
$light-gray: #e9ecef;
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.blue_nav {
  background: blue;
  width: 100%;
  padding: 6px 20px;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

.navbar-brand {
  img {
    cursor: pointer;
  }
}

.form-check {
  padding-right: 5rem;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-top: 0;
}

.blue_child {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 2px 10px;
  section {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .stay-in-touch {
    text-align: left;
  }
  .reach-out {
    text-align: right;
  }
  p {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 25px;
    letter-spacing: -0.05em;
    margin-bottom: 0;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    a:hover {
      color: #e4dede;
    }
  }
}

.navbar-toggler:focus {
  outline: 1px solid $primary !important;
}

.nav_holder {
  position: fixed;
  z-index: 2000;
  top: 0;
  width: 100%;
  nav {
    width: 100%;
    background: $secondary;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.05);

    /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); */
  }
}

.navbar-outer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;

  &.open {
    /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15); */
  }
}

.register_btn {
  font-family: Gotham;
  font-weight: normal;
  box-shadow: none;
  outline: 0;
  display: flex;
  font-weight: 600;
  gap: 1rem;
  padding-left: 1rem;

  margin-top: 1.3rem;
  a {
    white-space: nowrap;
  }
  @media screen and (min-width: 992px) {
    margin-top: 0;
  }

  .btn {
    font-weight: 600;
  }

  .secondary-btn {
    border: 1px solid $primary;
    border-radius: 4px;
    color: $primary;
    box-shadow: none;
    outline: 0;
    font-family: Gotham;
    font-weight: normal;
  }

  .primary-btn {
    background: $primary;
    border-radius: 4px;
    color: $secondary;
    box-shadow: none;
    outline: 0;
    font-family: Gotham;
    font-weight: normal;
  }

  /* button:first-child {
    background: $yellow;
    color: $secondary;
    margin-right: 15px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.05em;
    box-shadow: none;
    outline: 0;
  }
  button:nth-child(2) {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.05em;
    box-shadow: none;
    outline: 0;
  } */
}
.nav-link {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #000000;
  text-align: left;
  a {
    width: 100%;
    white-space: nowrap;
  }
}

.shelta-active-link {
  color: $primary;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
}
.header_text {
  font-family: Gotham;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: $secondary;
  margin-top: 2rem;
}

.user__switch {
  padding: 6rem 20px 0;
  margin-bottom: 3rem;
  span:first-child {
    padding: 16px 32px;
    border: 0.5px solid rgba(0, 51, 234, 0.7);
    color: $primary;
    cursor: pointer;
  }
  span:nth-child(2) {
    padding: 16px 32px;
    border: 0.5px solid rgba(0, 51, 234, 0.7);
    border-radius: 0px 5px 5px 0px;
    color: $primary;
    cursor: pointer;
  }
}
.activeUser {
  background: $primary;
  border-radius: 5px 0px 0px 5px;
  color: #fff !important;
}
.inactive {
  border: 0.5px solid rgba(0, 51, 234, 0.7);
  border-radius: 5px 0px 0px 5px;
}

.checkboxlabel {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #000000;
}
.checkboxlabel ::after {
  cursor: pointer !important;
}

.modal__btn {
  margin: 2rem 0 4rem 0;
  button {
    width: 180px;
    height: 45px;
    left: 595px;
    top: 339px;
    background: $yellow;
    border-radius: 5px;
    color: $secondary;
    outline: 0;
    box-shadow: none;
  }
}
.form-check {
  padding-right: 5rem;
}

.modal_backdrop {
  background-color: $primary !important;
}

//BOOTSTRAP DROPDOWN RESET

.dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  border-bottom: 0.5px solid $light-gray;
}

.dropdown-item .nav-link {
  font-weight: 400 !important;
}

.dropdown-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.nav-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;

  img {
    width: 16px;
    transform: rotate(90deg);
    transition: 0.2s;
    gap: 1rem;

    @media screen and (min-width: 1024px) {
      margin-left: 8px;
    }
  }
}

a[aria-expanded="true"] img {
  transform: rotate(-90deg);
}
.dropdown-item .nav-link {
  padding-left: 0 !important;
}

.navbar {
  padding: 1rem 0rem;
  @media screen and (min-width: 992px) {
    padding: 0.5rem 1.5rem;
  }
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  background-color: $gray !important;

  @media screen and (min-width: 1024px) {
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.4);
  }
}

.dropdown-toggle::after {
  display: none;
}

.navbar-toggler[aria-expanded="true"] #open-menu {
  display: none;
}

.navbar-toggler[aria-expanded="false"] #close-menu {
  display: none;
}

.navbar button:focus {
  outline-width: 0 !important;
}

.dropdown-divider {
  margin: 0 !important;
}

// User type modal ends

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  .blue_child {
    .stay-in-touch,
    .reach-out {
      font-size: 10px;
    }
  }
}

@media screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  .blue_child {
    .stay-in-touch,
    .reach-out {
      font-size: 10px;
    }
  }
}

@media only screen and (min-width: 991px) {
  .nav_holder {
    width: 100%;
    margin-bottom: 9rem;
    height: fit-content;
  }
}

.navbar-brand img {
  width: 70%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .navbar-brand {
    img {
      /* width: 67%; */
    }
  }
}
@media only screen and (min-width: 1024px) {
  .navbar-brand {
    img {
      width: 100%;
    }
  }
  .blue_nav {
    padding: 6px 6px;
  }

  #bg_color,
  .dropdown-bg {
    background: $gray !important;

    @media screen and (min-width: 992px) {
      background-color: $secondary !important;
    }
  }
}

// @media only screen and (max-width: 375px) {

//   .nav_holder, nav {
//     top: 4% !important;
//   }

// }

// @media only screen and (max-width: 414px) {
//   .ovarlay-logo-div > div[data-v-d9ae3272]:nth-child(2) {
//     top: 11%;
//     left: 36%;
//   }
// }
// @media only screen and (max-width: 375px) {
//   .ovarlay-logo-div > div[data-v-d9ae3272]:nth-child(2) {
//     top: 11%;
//     left: 35%;
//   }
// }
</style>
