import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
// const mutations = require(".mutations");
// const actions = require(".actions");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiWarning: "",
    apiSuccess: "",
    apiFailed: "",
    pageLoader: false,
    pageUrl: "",
    pageData: ""
    // safeType: ""
  },
  mutations: mutations,
  // mutations: {
  //   setSafe: (state, value) => {
  //     state.safeType = value;
  //     console.log("mutation state safe", state, value);
  //   }
  // },
  actions: actions,
  // actions: {
  //   setSafe: ({ commit }, value) => {
  //     commit("setSafe", value);
  //     console.log("action safe", value);
  //   }
  // },
  modules: {}
});
