<template>
  <div>
    <footer class="footer_holder">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="footer-about">
              <img src="../assets/logo.svg" alt="logo" />
              <p>
                Shelta is a PropTech Startup that provides guaranteed rent for
                Landlords & flexible (monthly, biannual, quarterly) rent payment
                for tenants. Shelta takes away the friction between
                Landlord-Tenant relationships through our rent financing
                solution.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="footer-link-holder">
              <div class="row">
                <div class="col-6 col-sm-6">
                  <ul class="flink">
                    <p>Company</p>
                    <li>
                      <router-link to="/aboutus" class="router-link-active"
                        >About</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/safe" class="router-link-active"
                        >Safe</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/rentpayer" class="router-link-active"
                        >Rent Payer</router-link
                      >
                    </li>
                    <li>
                      <a href="https://smart.myshelta.com/">Smart</a>
                    </li>
                    <!-- <li>
                      <router-link to="/sure" class="router-link-active"
                        >SURE 2023</router-link
                      >
                    </li> -->
                  </ul>
                </div>
                <div class="col-6 col-sm-6">
                  <ul class="flink">
                    <p>Support</p>
                    <li>
                      <router-link to="/faq" class="router-link-active"
                        >FAQs</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contactus" class="router-link-active"
                        >Contact us</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/signup" class="router-link-active"
                        >Register</router-link
                      >
                    </li>
                    <li>
                      <!-- <a href="https://blog.myshelta.com/">Resources/Blog</a> -->
                      <router-link to="/blogs" class="router-link-active"
                        >Resources/Blog</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-3">
            <div class="">Support</div>
          </div> -->
          <div class="col-md-4">
            <ul class="flink">
              <p>Offices</p>
              <h6>
                4. Amurie Omanze, Off Samuel Ladoke Akintola Boulevard, Garki 2
                900001, Abuja, Federal Capital Territory.
              </h6>
              <h6>
                3. Abang Odok Close By AB Army Hotel, Mekenge Layout, Calabar.
              </h6>
              <h5>Opening hours: 9am - 5pm | Mon - Fri</h5>
            </ul>
          </div>

          <div class="col-md-6 col-lg-8 d-none">
            <div class="feedback_holder">
              <div class="news_letter">
                <h6>Sign up to our newsletters</h6>
                <p>
                  Be one of the first to know about product launches, promotions
                  and more.
                </p>
                <div class="newsletter_holder">
                  <input
                    v-model="email"
                    class="form-control"
                    type="text"
                    placeholder="Enter your email"
                  />
                  <button
                    @click.prevent="newsLetter()"
                    class="btn newsletter_btn"
                  >
                    <img
                      src="../assets/images/arrow-black.svg"
                      alt="feedback"
                    />
                    <i
                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                      v-if="loader"
                    ></i>
                  </button>
                </div>
                <div v-if="errors.length">
                  <ul class="error-ul">
                    <li
                      v-for="error in errors"
                      :key="error.error"
                      class="errorMessage"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 d-none">
            <div class="links_holder">
              <div class="row">
                <div class="col-6">
                  <ul class="ulist">
                    <p>COMPANY</p>
                    <li>
                      <a href="https://blog.myshelta.com/">Blog</a>
                      <!-- <router-link to="/blogs" class="router-link-active">
                        Blog
                      </router-link> -->
                    </li>
                    <li>
                      <router-link to="/aboutus" class="router-link-active"
                        >About us</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/tenant" class="router-link-active"
                        >Tenant</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/landlord" class="router-link-active"
                        >Landlord</router-link
                      >
                    </li>
                    <li>
                      <a href="https://myshelta.com/careers/">Careers</a>
                      <!-- <router-link to="/career" class="router-link-active"></router-link> -->
                    </li>
                    <li>
                      <router-link to="/shelta" class="router-link-active"
                        >Property management</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-6">
                  <ul class="ulist">
                    <p>SUPPORT</p>
                    <li>
                      <router-link to="/faq" class="router-link-active">
                        Help & FAQ
                      </router-link>
                    </li>
                    <li>
                      <a href="mailto:hello@myshelta.com">
                        hello@myshelta.com
                      </a>
                    </li>
                    <!-- <li>
                      <a href="sheltaoffice@gmail.com">
                        sheltaoffice@gmail.com
                      </a>
                    </li> -->
                    <li>
                      <a
                        href="https://api.whatsapp.com/send?phone=2348061796909"
                      >
                        (+234) 0806 179 6909
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer_bottom">
      <div class="footer_bottom_holder">
        <h6>
          &copy; {{ year }} A Product of
          <img class="abl-logo" src="../assets/images/abella-logo.svg" alt="" />
        </h6>
        <div class="social-icon-div">
          <a href="http://www.facebook.com/myshelta" class="fa fa-facebook"></a>
          <a
            href="http://www.instagram.com/myshelta"
            class="fa fa-instagram"
          ></a>
          <a href="http://www.twitter.com/myshelta" class="fa fa-twitter"></a>
          <a
            href="http://www.linkedin.com/company/myshelta"
            class="fa fa-linkedin"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "FooterMain",

  data() {
    return {
      year: "",
      email: "",
      errors: [],
      loader: false
    };
  },

  mounted() {
    this.year = new Date().getFullYear();
    // console.log('Year in full', this.year);
  },
  methods: {
    newsLetter: function() {
      this.loader = true;
      this.errors = [];
      if (this.email === "") {
        this.errors.push("Email is required!");
        this.loader = false;
      } else {
        Api.postRequestNoAuth(`newslettersignup`, {
          email: this.email
        })
          .then(res => {
            this.email = "";
            this.loader = false;
            this.$store.commit("setApiSuccess", "Success");
          })
          .catch(err => {
            this.loader = false;
            console.log(err);
            // this.$store.commit("setApiFailed", "Error occured");
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$black: #000000;
$sheltaBlack: #000a2f;
$gray: #f4f6fd;
$offGrey: #555c74;
// $fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

.footer_holder {
  // margin-top: 20px;
  // padding: 3rem 0;
  padding: 3rem 77px;
  background-color: $gray;

  > .row {
    @media screen and (min-width: 768px) {
      align-items: center;
    }
  }
}

.feedback_holder {
  text-align: left;
  max-width: 450px;
}
.links_holder {
  text-align: left;
  /* padding-top: 5rem; */
  margin-top: 5rem;
  a {
    font-size: 12px;
  }

  @media screen and (min-width: 768px) {
    margin-top: 0;
    a {
      font-size: 14px;
    }
  }
}

// New footer begins
.footer-about {
  text-align: left;
  p {
    padding-top: 20px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $offGrey;
    text-align: left;
  }
}

.flink {
  list-style-type: none;
  // padding-left: 0;
  text-align: left;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
    color: $sheltaBlack;
  }
  li {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: $offGrey;
    padding: 8px 0;
  }
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $offGrey;
  }
  h5 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $sheltaBlack;
  }
}
// New Footer ends

.news_letter {
  margin-top: 25px;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: -0.05em;
    color: $primary;
    margin-bottom: 0;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 37px;
    letter-spacing: -0.05em;
    color: $black;
  }
}

.newsletter_holder {
  position: relative;
  width: 100%;
  margin-top: 2rem;
  @media screen and (min-width: 768px) {
    /* width: 57%; */
  }

  input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #cabfbf;
    box-shadow: none;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    width: 100%;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.49);
  }
  .newsletter_btn {
    position: absolute;
    background: $yellow;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    bottom: 8%;
    right: 0;
  }
}

.errorHeader {
  font-family: Gotham;
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  // margin-left: 1em;
}
.errorMessage {
  font-family: Gotham;
  font-weight: normal;
  color: #dc3545;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}

.ulist {
  list-style-type: none;
  padding-left: 0;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    color: $black;
    margin-bottom: 0;
    padding-left: 5px;
  }
  li {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.05em;
    color: $black;
    margin: 6px;
  }
}

.footer_bottom {
  background-color: $primary;
}
.footer_bottom_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    // letter-spacing: -0.05em;
    padding-top: 11px;
    color: $white;
  }
}
.abl-logo {
  margin-bottom: 4px;
}

.fa-facebook,
.fa-instagram,
.fa-twitter,
.fa-linkedin {
  padding: 15px;
  font-size: 30px;
  width: 13.55px !important;
  height: 13.55px !important;
  text-align: center;
  text-decoration: none;
  margin: 15px 2px 5px 2px;
  position: relative;
}

.fa:hover {
  // opacity: 0.7;
  color: #ffb100;
}

.fa-facebook {
  // background: #3b5998;
  color: white;
  border: 1px solid #fff;
  border-radius: 50px;
}
.fa-facebook::before {
  position: absolute;
  left: 33%;
  top: 23%;
  font-size: 1rem;
}

.fa-twitter {
  // background: #55acee;
  color: white;
  border: 1px solid #fff;
  border-radius: 50px;
}
.fa-twitter::before {
  position: absolute;
  left: 26%;
  top: 24%;
  font-size: 1rem;
}

.fa-linkedin {
  // background: #007bb5;
  color: white;
  border: 1px solid #fff;
  border-radius: 50px;
}
.fa-linkedin::before {
  position: absolute;
  left: 27%;
  top: 20%;
  font-size: 1rem;
}

.fa-instagram {
  // background: #125688;
  border: 1px solid #fff;
  color: white;
  border-radius: 50px;
}
.fa-instagram::before {
  position: absolute;
  left: 27%;
  top: 20%;
  font-size: 1rem;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.49);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.49);
}

::placeholder {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.49);
}

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer_bottom_holder {
    padding: 0;
    h6 {
      font-size: 13px;
    }
  }
}
// @media screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
//   .footer_bottom_holder {
//     padding: 0 5px;
//   }
// }
@media only screen and (max-width: 599px) {
  .footer_holder {
    padding: 3rem 0 0.5rem;
  }
  // New footer starts
  .flink {
    padding-top: 15px;
    padding-left: 0;
  }
  // New footer ends
  .footer_bottom_holder {
    padding: 0 5px;
  }
  .footer_logo {
    img {
      width: 22%;
    }
  }
  .news_letter {
    h6 {
      font-size: 22px;
    }
  }
  .newsletter_holder {
    input {
      width: 100%;
    }
    .newsletter_btn {
      left: 77%;
    }
  }
  .ulist {
    padding-left: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer_logo {
    img {
      width: 17%;
    }
  }
  .news_letter {
    h6 {
      font-size: 26px;
    }
  }
  .newsletter_holder {
    input {
      width: 100%;
    }
    .newsletter_btn {
      left: 81%;
    }
  }

  .ulist {
    padding-left: 0;
  }
  .links_holder {
    padding-top: 0px;
  }
}
</style>
