<template>
  <div>
    <!-- Brands begins -->
    <div class="brands-holder">
      <div class="container">
        <div>
          <p class="brands-text">Trusted by brands you trust.</p>
        </div>
        <div class="">
          <div class="row">
            <div class="brands-logo">
              <div class="row">
                <div class="d-flex align-items-center">
                  <div class="col pb-4">
                    <img src="../assets/lovedby/gtco.svg" alt="gtco" />
                  </div>
                  <div class="col pb-4">
                    <img
                      src="../assets/lovedby/printrite .svg"
                      alt="printrite"
                    />
                  </div>
                </div>
              </div>
              <div class="row single-brand_logo">
                <div class="d-flex align-items-center ">
                  <div
                    class="col pb-4 mx-auto align-items justify-items instead"
                  >
                    <img src="../assets/home/sie.svg" alt="gtco" />
                  </div>
                  <!-- <div class="col">
                        <img
                          src="../assets/lovedby/printrite .svg"
                          alt="printrite"
                        />
                      </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Brands ends -->

    <!-- Smart section begins -->
    <div class="smart">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="smart-left">
              <img src="../assets/images/smartfram.svg" alt="smart" />
              <p class="d-block d-sm-none d-none d-sm-block d-md-none mt-5">
                Also, you enjoy amazing incentives as bonuses from the volume of
                transaction generated by you and your recruits.
              </p>
              <button
                @click="smartLink"
                class="learn-more btn d-block d-sm-none d-none d-sm-block d-md-none pl-0"
              >
                <a href="https://smart.myshelta.com/" target="blank">
                  Learn more <i class="fa fa-arrow-right"></i>
                </a>
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="smart-right">
              <h6>SMART</h6>
              <h4>
                Enjoy amazing rewards when you sign-up for our Realtors Program.
              </h4>
              <p>
                With SMART by Shelta, you earn direct commission in cash up to
                40% on transactions.
              </p>
              <p class="d-none d-md-block">
                Also, you enjoy amazing incentives as bonuses from the volume of
                transaction generated by you and your recruits.
              </p>
              <button class="btn d-none d-md-block pl-0" @click="smartLink">
                <a href="https://smart.myshelta.com/" target="blank">
                  Learn more <i class="fa fa-arrow-right"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Smart section ends -->
    <hr class="m-0" style="border-top: 1px solid #f9f9f9;" />

    <!-- Loved by section starts -->
    <div class="container-fluid">
      <div class="loved-by">
        <div class="row">
          <div class="col-md-6 loveby-row">
            <div class="lovedby-left-top">
              <h6>Loved by 10,000+ Africans</h6>
              <div class="line">
                <!-- <span>.</span> -->
                <hr />
              </div>
              <div class="lb-btn">
                <button class="btn" @click="createAccountMiddleHero">
                  <router-link to="/signup">
                    Create a free account
                  </router-link>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-none d-md-block">
            <div class="row">
              <div class="col-md-3 pt-0 lovedimg">
                <img src="../assets/lovedby/lb1.svg" alt="" />
              </div>
              <div class="col-md-3 pt-0 lovedimg">
                <img src="../assets/lovedby/lb2.svg" alt="" />
              </div>
              <div class="col-md-3 pt-0 lovedimg">
                <img src="../assets/lovedby/lb3.svg" alt="" />
              </div>
              <div class="col-md-3 pt-0 lovedimg">
                <img src="../assets/lovedby/lb4.svg" alt="" />
              </div>
            </div>
            <div class="row flex-row-reverse ">
              <div class="col-md-3 lovedimg">
                <img src="../assets/lovedby/lb7.svg" alt="" />
              </div>
              <div class="col-md-3 lovedimg">
                <img src="../assets/lovedby/lb6.svg" alt="" />
              </div>
              <div class="col-md-3 lovedimg">
                <img src="../assets/lovedby/lb5.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-md-block">
          <div class="row">
            <!-- <div class=""> -->
            <div class="col lovedbyimg2">
              <img src="../assets/lovedby/lb8.svg" alt="" />
            </div>
            <div class="col lovedbyimg2">
              <img src="../assets/lovedby/lb9.svg" alt="" />
            </div>
            <div class="col lovedbyimg2">
              <img src="../assets/lovedby/lb10.svg" alt="" />
            </div>
            <div class="col lovedbyimg2">
              <img src="../assets/lovedby/lb11.svg" alt="" />
            </div>
            <div class="col lovedbyimg2">
              <img src="../assets/lovedby/lb12.svg" alt="" />
            </div>
            <div class="col lovedbyimg2">
              <img src="../assets/lovedby/lb13.svg" alt="" />
            </div>
            <div class="col lovedbyimg2">
              <img src="../assets/lovedby/lb14.svg" alt="" />
            </div>
            <div class="col lovedbyimg2">
              <img src="../assets/lovedby/lb15.svg" alt="" />
            </div>

            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Loved by section ends -->

    <!-- People we serve begins -->
    <div class="people-we-serve">
      <div class="container-fluid">
        <p class="people-text">People we serve</p>
        <div class="row">
          <div class="col-md-4">
            <div class="lto">
              <h3>500</h3>
              <h6>Landlords</h6>
              <small>Get your complete rent without delay or stress.</small>
              <div>
                <button class="btn" @click="peopleWeServeLandlord">
                  <router-link to="/landlord">
                    Learn more <i class="fa fa-arrow-right"></i>
                  </router-link>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="lto">
              <img class="blue" src="../assets/images/bluebadge.svg" alt="" />
              <img class="gold" src="../assets/images/goldbadge.svg" alt="" />
              <h3>3,000</h3>
              <h6>Tenants</h6>
              <small>We pay your rent so you enjoy sustainable living.</small>
              <div>
                <button class="btn" @click="peopleWeServeTenant">
                  <router-link to="/tenant">
                    Learn more <i class="fa fa-arrow-right"></i>
                  </router-link>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="lto">
              <h3>30</h3>
              <h6>Organizations</h6>
              <small>Onboard your staff and enjoy more benefit.</small>
              <div>
                <button class="btn" @click="peopleWeServeOrganization">
                  <router-link to="/organization">
                    Learn more <i class="fa fa-arrow-right"></i>
                  </router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- People we serve ends -->

    <!-- What our clients say begin -->
    <div class="our-clients-say">
      <div class="container-fluid">
        <div class="client-text">
          <h6 class="">What our clients say</h6>
          <div class="clients-next d-none d-md-block">
            <span
              :class="[theClient === 'landlord' ? 'active-next' : '']"
              @click="theClient = 'landlord'"
              ><i class="fa fa-chevron-left"></i
            ></span>
            <span
              :class="[theClient === 'tenant' ? 'active-next' : '']"
              @click="theClient = 'tenant'"
              ><i class="fa fa-chevron-right"></i
            ></span>
          </div>
        </div>
        <div
          class="row"
          v-if="theClient === 'landlord'"
          style="background: #F9F9FF; border-radius: 20px;"
        >
          <div class="col-md-6 col-lg-6 client-img">
            <div class="client-image">
              <img src="../assets/home/landlordImg.svg" alt="landlord" />
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="client-right">
              <h5>Happy Landlord</h5>
              <p>
                "I wish I would have thought of it first. Shelta is the next big
                property management company."
              </p>
              <h6>- Adedayo E.</h6>
              <small>Landlord</small>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="theClient === 'tenant'"
          style="background: #F9F9FF; border-radius: 20px;"
        >
          <div class="col-md-6 col-lg-6">
            <div class="client-image">
              <img src="../assets/home/tenantsimg.svg" alt="tenant" />
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="client-right">
              <h5>Happy Tenant</h5>
              <p>
                "Seriously, Shelta was my saver in times of need. I was in a
                situation where I had just 8 days to leave my previous house,
                and Shelta came to my rescue. Beyond their quick response, I am
                happy with the flexibility payment because it takes a whole lot
                of financial burden on myself. Thank you, Shelta.”
              </p>
              <h6>- Eliana M.</h6>
              <small>Tenant</small>
            </div>
          </div>
        </div>
      </div>
      <div
        class="clients-next d-block d-sm-none d-none d-sm-block d-md-none mt-4"
      >
        <span
          :class="[theClient === 'landlord' ? 'active-next' : '']"
          @click="theClient = 'landlord'"
          ><i class="fa fa-chevron-left"></i
        ></span>
        <span
          :class="[theClient === 'tenant' ? 'active-next' : '']"
          @click="theClient = 'tenant'"
          ><i class="fa fa-chevron-right"></i
        ></span>
      </div>
    </div>
    <!-- What our clients say ends -->

    <!-- Ready to get started starts -->
    <div class="">
      <div class="container-fluid reomove-row">
        <div class="ready-to-start">
          <!-- <div>
                <img src="../assets/lovedby/iconsmerge.svg" alt="">
              </div> -->
          <h4>Ready to get started</h4>
          <div class="ready-buttons">
            <button class="btn contact" @click="createAccountLastHero">
              <router-link to="/signup">
                Create a free account
              </router-link>
            </button>
            <button class="btn sales" @click="contactSalesLast">
              <router-link to="contactus">
                Contact Sales
              </router-link>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Ready to get started ends -->
  </div>
</template>

<script>
export default {
  name: "HomePart",
  components: {},
  data() {
    return {
      theClient: "landlord"
    };
  },
  mounted() {},
  methods: {
    smartLink() {
      this.$gtag.event("smart-link-clicked", {
        event_category: "home page samrt link click",
        event_label: "Learn more button clicked",
        value: 1
      });
    },
    createAccountLastHero() {
      this.$gtag.event("first-hero-create-account-clicked", {
        event_category: "first hero create account click",
        event_label: "Create a free account button clicked",
        value: 1
      });
    },
    createAccountMiddleHero() {
      this.$gtag.event("middle-hero-create-account-clicked", {
        event_category: "middle hero create account click",
        event_label: "Create a free account button clicked",
        value: 1
      });
    },
    contactSalesLast() {
      this.$gtag.event("contact-sales-last-hero-clicked", {
        event_category: "contact sales click",
        event_label: "Contact sales button clicked",
        value: 1
      });
    },
    peopleWeServeLandlord() {
      this.$gtag.event("people-we-serve-lanlord-clicked", {
        event_category: "people we serve landlord events",
        event_label: "Learn more button clicked",
        value: 1
      });
    },
    peopleWeServeTenant() {
      this.$gtag.event("people-we-serve-tenant-clicked", {
        event_category: "people we serve tenant events",
        event_label: "Learn more button clicked",
        value: 1
      });
    },
    peopleWeServeOrganization() {
      this.$gtag.event("people-we-serve-organization-clicked", {
        event_category: "people we serve organization events",
        event_label: "Learn more button clicked",
        value: 1
      });
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
$sheltaBlack: #000a2f;
$primary: #0033ea;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;
$listing-bg: #64abb2;
$offGray: #555c74;
$bg: #f9f9ff;

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
  text-decoration: inherit;
}

// Brands that trust us  begins
.brands-holder {
  background-color: $bg;
  padding: 40px 10px;
}
.brands-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  text-align: center;
  color: $sheltaBlack;
  margin-bottom: 35px;
}
.brands-logo {
  margin: auto;
  padding: 0 77px;
  display: flex;
  margin-top: 10px;
  // overflow: scroll;
  .row {
    padding: 0;
  }
  img {
    padding: 0 20px;
  }
}
.brands-logo::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.brands-logo {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media only screen and (max-width: 599px) {
  .brands-text {
    font-size: 22px;
  }
  .brands-logo {
    padding: 0 0px;
    display: block;
    img {
      padding: 10px;
    }
  }
  .single-brand_logo {
    display: block;
  }
}
// Brands that trust us ends

// Smart section bgins

.smart {
  padding-top: 80px;
  padding-left: 77px;
  //   padding-right: 55px;
  padding-right: 77px;
  padding-bottom: 80px;
}

.smart-left {
  margin-right: 20px;
  img {
    width: 100%;
  }
}
.smart-right {
  text-align: left;
  margin-left: 20px;
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 125%;
    text-transform: uppercase;
    color: $primary;
  }
  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    color: $sheltaBlack;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $offGray;
  }
  button {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    text-align: center;
    color: $primary;
    outline: 0;
    box-shadow: none;
    i {
      padding-left: 10px;
      font-size: 17px;
    }
  }
}

.learn-more {
  display: flex;
  a {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    color: $primary;
    outline: 0;
    box-shadow: none;
    text-align: left;
    i {
      padding-left: 10px;
      font-size: 17px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .smart {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    padding-top: 40px;
    .row {
      flex-direction: column-reverse;
    }
  }
  .hero-row {
    padding: 0;
  }
  .smart-right {
    margin-left: 0;
    h6 {
      text-align: center;
    }
    h4 {
      font-size: 22px;
      text-align: center;
    }
  }
  .smart-left {
    padding-top: 20px;
    margin-right: 0;
    img {
      width: 100%;
    }
    p {
      text-align: left;
      font-family: Lato;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: $offGray;
    }
  }
}
// Smart section ends

// Loved by many starts
.loveby-row {
  padding-left: 0;
}
.loved-by {
  margin: 0px 77px 0 77px;
}
.lovedby-left-top {
  background-color: $sheltaBlack;
  padding-bottom: 20px;
  border-radius: 10px;
  margin-top: 40px;
  // height: 214px;
  padding-bottom: 65px;
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 66px;
    text-align: center;
    color: $whiteText;
    padding-top: 25px;
  }

  .line {
    width: 90px;
    margin: auto;
    position: relative;
    span {
      font-size: 45px;
      position: absolute;
      color: $whiteText;
      bottom: -21px;
      right: -9px;
    }
    hr {
      background-color: $whiteText;
    }
  }
}

.lb-btn {
  button {
    background-color: $primary;
    color: $whiteText;
    margin-top: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    box-shadow: none;
    outline: 0;
    height: 50px;
  }
}
.lovedbyimg2 {
  padding: 4px;
  img {
    width: 100%;
  }
}
.lovedimg {
  padding: 4px;
  img {
    width: 100%;
  }
}
@media only screen and (max-width: 599px) {
  .loveby-row {
    padding: 0;
  }
  .line {
    span {
      bottom: -20px !important;
    }
  }
  .loved-by {
    margin: 0;
  }
  .lovedby-left-top {
    border-radius: 0;
    h6 {
      font-size: 23px;
    }
  }
}
// Loved by many ends

// People we serve starts
.people-we-serve {
  background: $bg;
  padding: 60px 77px;
}
.people-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: $sheltaBlack;
  padding-bottom: 20px;
}

.lto {
  border: 1px solid #d4d2e3;
  border-radius: 24px;
  padding: 60px 20px;
  position: relative;
  height: 372px;
  .blue {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    border-top-right-radius: 20px;
  }
  .gold {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    border-top-right-radius: 20px;
  }
  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 66px;
    text-align: center;
    color: rgba(170, 173, 186, 0.2);
  }
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    text-align: center;
    color: $sheltaBlack;
  }
  small {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: $offGray;
    word-break: break-word;
  }
  button {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    text-align: center;
    color: $primary;
    margin-top: 25px;
    box-shadow: none;
    outline: 0;
    i {
      padding-left: 10px;
      font-size: 17px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .people-we-serve {
    padding: 60px 0;
    padding-bottom: 20px;
  }
  .people-text {
    font-size: 22px;
    padding-bottom: 0;
  }
  .lto {
    margin-bottom: 20px;
    padding: 40px 15px;
    height: 317px;
    h3 {
      font-size: 35px;
    }
    h6 {
      font-size: 25px;
    }
  }
}
// People we serve ends

// What our clients say begins

.our-clients-say {
  padding: 50px 77px 40px 77px;
}
.client-text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    color: $sheltaBlack;
    text-align: left;
    padding-bottom: 25px;
  }
}

.active-next {
  background: $primary;
  border: 1px solid $primary !important;
  i {
    color: $whiteText !important;
  }
}

.clients-next {
  span:first-child {
    margin-right: 10px;
  }
  span {
    border: 1px solid $offGray;
    cursor: pointer;
    padding: 15px 18px;
    border-radius: 50px;
    i {
      color: $offGray;
    }
  }
}

.client-img {
  padding-left: 0;
}
.client-image {
  img {
    width: 100%;
    border-radius: 10px;
  }
}
.client-right {
  padding-top: 90px;
  margin: 54px;
  h5 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    color: $sheltaBlack;
    text-align: left;
  }
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: $sheltaBlack;
    text-align: left;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $offGray;
    text-align: left;
  }
  small {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $offGray;
    text-align: left;
    display: flex;
  }
}

@media only screen and (max-width: 599px) {
  // .client-image {
  //   img {
  //     border-bottom-left-radius: 10px;
  //     border-bottom-right-radius: 10px;
  //     border-top-right-radius: 0;
  //     border-top-left-radius: 0;
  //   }
  // }
  .client-img {
    padding-left: 15px;
  }
  .our-clients-say {
    padding: 50px 0px 40px 0px;
  }
  .client-right {
    margin: 0;
    padding-top: 30px;
    padding-bottom: 20px;
    h5 {
      font-size: 22px;
      text-align: center;
    }
    h6 {
      font-size: 18px;
      text-align: center;
    }
    p {
      text-align: center;
    }
    small {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
  .client-text {
    h6 {
      font-size: 25px;
    }
  }
  .clients-next {
    span {
      padding: 9px 12px;

      // border-radius: 50% !important;
    }
  }
}
// What our clients say ends

// ready to start begins
.ready-to-start {
  // background-color: $primary;
  background: url("../assets/lovedby/readytostart.svg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 320px;
  padding: 40px 77px 0px 77px;
  margin: 0 54px 50px;
  position: relative;
  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 150%;
    text-align: center;
    color: $whiteText;
  }
}
.ready-buttons {
  button {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    width: 183px;
    height: 56px;
    border-radius: 6px;
    margin: 20px;
    box-shadow: none;
    outline: 0;
  }
  .contact {
    color: $primary;
    background-color: $whiteText;
  }
  .sales {
    color: $whiteText;
    background: $primary;
    border: 1px solid $whiteText;
  }
}

@media only screen and (max-width: 599px) {
  .reomove-row {
    padding-left: 0;
    padding-right: 0;
  }
  .ready-to-start {
    margin: 0 0px 30px;
    padding: 30px 0 20px 0;
    height: auto;
    h4 {
      font-size: 28px;
    }
  }
  .ready-buttons {
    display: flex;
    margin: 30px 20px;
    .contact {
      margin-right: 10px;
    }
    button {
      // display: flex;
      width: 100%;
      margin: 0;
    }
  }
}
// Ready to starts ends
</style>
