<template>
  <div id="app">
    <router-view v-if="!pageLoader" />
    <PageLoader v-if="pageLoader" />
    <NotificationSuccess class="success" />
    <NotificationWarning class="warning" />
    <NotificationFailed class="failed" />
    <div class="notify"></div>
  </div>
</template>
<script>
import PageLoader from "./components/PageLoader.vue";
import NotificationWarning from "./components/NotificationWarning.vue";
import NotificationFailed from "./components/NotificationFailed.vue";
import NotificationSuccess from "./components/NotificationSuccess.vue";

import { Service } from "./store/service";
const Api = new Service();
// import paystack from 'vue-paystack';
export default {
  name: "App",
  components: {
    NotificationWarning,
    NotificationFailed,
    NotificationSuccess,
    PageLoader
    // paystack
  },
  computed: {
    apiSuccess() {
      return this.$store.state.apiSuccess;
    },
    apiFailed() {
      return this.$store.state.apiFailed;
    },
    apiWarning() {
      return this.$store.state.apiWarning;
    },
    pageLoader() {
      return this.$store.state.pageLoader;
    }
  },
  watch: {
    apiSuccess() {
      this.handleSuccess();
    },
    apiFailed() {
      this.handleFailed();
    },
    apiWarning() {
      this.handleWarning();
    }
  },
  mounted() {
    if (localStorage.getItem("shelta")) {
      this.getUserProfile();
    }
  },
  methods: {
    async getUserProfile() {
      try {
        const id = JSON.parse(localStorage.getItem("shelta-user")).id;
        await Api.getRequest(`getuserbyid/${id}`)
          .then(res => {
            // console.log("User profile", res);
            // if (res.status == 401) {
            //   window.localStorage.clear();
            //   return (window.location.href = "https://myshelta.com/login");
            // }
          })
          .catch(err => {
            // if (err == "Request failed with status code 401")
            //   window.localStorage.clear();
            // return (window.location.href = "https://myshelta.com");
          });
      } finally {
        // console.log("closed");
      }
    },

    // it cantit add more than one success
    // even when i cloned it, i dont have a way to refer to the cloned node so
    // that i can later hide it.
    // it is using the old settimeout timing
    handleSuccess() {
      const success = document.querySelector(".success");
      // success = success.cloneNode(true);
      if (this.apiSuccess.length > 0) {
        const notify = document.querySelector(".notify");
        success.style.display = "flex";
        notify.appendChild(success);
      } else {
        success.style.display = "none";
      }
    },
    handleFailed() {
      const failed = document.querySelector(".failed");
      if (this.apiFailed.length > 0) {
        const notify = document.querySelector(".notify");
        failed.style.display = "flex";
        notify.appendChild(failed);
      } else {
        failed.style.display = "none";
      }
    },
    handleWarning() {
      const warning = document.querySelector(".warning");
      if (this.apiWarning.length > 0) {
        const notify = document.querySelector(".notify");
        warning.style.display = "flex";
        notify.appendChild(warning);
      } else {
        warning.style.display = "none";
      }
    }
  }
};
</script>

<style lang="scss">
$primary: #0033ea;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.modal-bg {
  background: $primary;
  color: white;
  position: relative;
}
.modal {
  margin: 3rem 0;
}

.notify {
  text-align: left;
  width: 90%;
  left: 5%;
  color: #fff;
  font-family: "Roboto-Light";
  position: fixed;
  z-index: 3000;
  // top: 0;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
}
@media (min-width: 425px) {
  .notify {
    width: 370px;
    left: calc((100% - 370px) / 2);
  }
}
@media (min-width: 768px) {
  .notify {
    width: 500px;
    left: calc((100% - 500px) / 2);
  }
}
</style>
