<template>
  <div class="success" id="notification">
    <div>
      <!-- <div>Entry(ies) successfully saved</div> -->
      <div class="info">{{ apiSuccess }}</div>
    </div>
    <div class="input-group">
      <span class="mark-checkbox"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationSuccess",
  components: {},
  data() {
    return {};
  },
  computed: {
    apiSuccess() {
      return this.$store.state.apiSuccess;
    }
  },
  methods: {}
};
</script>

<style scoped>
.success {
  text-align: left;
  /* background-color: #1AB301; */
  border: 1px solid #1ab301;
  background-color: #fff;
  color: #1ab301;
  border-radius: 5px 5px;
  width: 100%;
  padding: 0 5%;
  /* color: #fff; */
  font-family: "Roboto-Light";
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-bottom: 5px;
  font-size: 14px;
}
.info {
  font-size: 14px;
  text-transform: capitalize;
}
.input-group {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  /* background: #FFF; */
  background: #1ab301;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mark-checkbox {
  display: inline-block;
  /* border-color: #1AB301; */
  border-color: #fff;
  border-style: solid;
  border-width: 2px 2px 0 0;
  width: 15px;
  height: 8px;
  transform: rotate(135deg);
  margin-bottom: 5px;
}
@media (min-width: 425px) {
  .success {
    padding: 0 25px;
  }
}
@media (min-width: 768px) {
  .success {
    padding: 0 25px;
    font-size: 16px;
  }
}
</style>
