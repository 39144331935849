<template>
  <div class="warning" id="notification">
    <div>
      <!-- <div>Sending Entry(ies)...</div> -->
      <div class="info">{{ apiWarning }}</div>
    </div>
    <!-- <div class="input-group">
            <img src="@/assets/images/white-button-logo.svg" alt="button image">
        </div> -->
  </div>
</template>

<script>
export default {
  name: "NotificationWarning",
  components: {},
  data() {
    return {};
  },
  computed: {
    apiWarning() {
      return this.$store.state.apiWarning;
    }
  },
  methods: {}
};
</script>

<style scoped>
.warning {
  text-align: left;
  /* background-color: #ff9c2a; */
  background-color: #fff;
  border: 1px solid #ff9c2a;
  border-radius: 5px 5px;
  width: 100%;
  padding: 0 5%;
  /* color: #fff; */
  color: #ff9c2a;
  font-family: "Roboto-Light";
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-bottom: 5px;
  font-size: 14px;
}
.info {
  font-size: 14px;
  text-transform: capitalize;
}
.input-group {
  position: relative;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
}
@media (min-width: 425px) {
  .warning {
    padding: 0 25px;
  }
}
@media (min-width: 768px) {
  .warning {
    padding: 0 25px;
    font-size: 16px;
  }
}
</style>
