export default {
  setApiWarning(state: any, stringValue: any) {
    state.apiWarning = stringValue;
    setTimeout(() => {
      state.apiWarning = "";
    }, 4000);
  },
  setApiSuccess(state: any, stringValue: any) {
    state.apiSuccess = stringValue;
    setTimeout(() => {
      state.apiSuccess = "";
    }, 4000);
  },
  setApiFailed(state: any, stringValue: any) {
    state.apiFailed = stringValue;
    setTimeout(() => {
      state.apiFailed = "";
    }, 4000);
  },

  setPaginationData(state: any, stringValue: any) {
    state.pageData = stringValue;
    console.log("state:", state, "value:", stringValue);
  },

  setPaginationUrl(state: any, stringValue: any) {
    state.pageUrl = stringValue;
    console.log("state:", state, "value:", stringValue);
  }

  // setSafe: (state: any, value: any) => {
  //         state.safeType = value;
  //         // console.log("mutation state safe", state, value);
  //       },
};
