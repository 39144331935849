<template>
  <div>
    <div
      class="modal fade"
      id="popupInfo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="popupInfo"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div
          class="modal-content popup-content"
          style="margin-bottom: 50px; border-radius: 15px;"
        >
          <!-- <div class="closePopup" style="border-radius: 20px 20px 0 0;">
            <h6>Transfer funds to project</h6>
            <small data-dismiss="modal" aria-label="Close">x</small>
          </div> -->
          <div class="modal-body p-0">
            <div class="blue-bg">
              <small class="closex" @click="closePopup()" data-dismiss="modal" aria-label="Close"
                >X</small
              >
              <div class="white-text">
                <h1>Can't find what you are looking for?</h1>
                <h5>Let us know what you need. We are happy to help!</h5>
                <button class="btn">
                  <a href="https://api.whatsapp.com/send?phone=2348061796909">
                    CHAT US NOW
                  </a>
                </button>
              </div>
            </div>
            <div class="bottom-footer">
              <h6>You can check here:</h6>
              <div class="row">
                <div class="col-md-4">
                  <div class="footer-text">
                    <h5
                      @click="routeTo('tenant')"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      TENANT
                      <img
                        src="../assets/icons/chevron-right.svg"
                        alt="arrow"
                      />
                    </h5>
                    <p>Rent financing, No Agency Fee</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="footer-text">
                    <h5
                      @click="routeTo('landlord')"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      LANDLORD
                      <img
                        src="../assets/icons/chevron-right.svg"
                        alt="arrow"
                      />
                    </h5>
                    <p>Guaranteed Rent, Property Management</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="footer-text">
                    <h5
                      @click="routeTo('smart')"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      SMART ASSOCIATE
                      <img
                        src="../assets/icons/chevron-right.svg"
                        alt="arrow"
                      />
                    </h5>
                    <p>Earn remotely with real estate</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "InfoPopup",
  components: {},
  data() {
    return {
      breakdownPayment: [],
      breakdown: [],
      panel: "",
      panel2: false
    };
  },
  created() {
    this.$root.$refs.F = this;
  },
  mounted() {},
  methods: {
    routeTo(data) {
      if (data === "landlord") {
        this.$router.push({
          path: `/landlord`
        });
      }
      if (data === "tenant") {
        this.$router.push({
          path: `/tenant`
        });
      }
      if (data === "smart") {
        window.open("https://smart.myshelta.com/", "_blank");
      }
    },
    openInfo() {
      $("#popupInfo")
        .addClass("fade")
        .modal("show");
    },
    closePopup() {
      localStorage.setItem("popup", "NO");
    },
  }
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$primary: #0033ea;
$shelta-black: #000a2f;
$shelta-yellow: #ffb100;
$shelta-gray: #2b3352;
$shelta-gray2: #555c74;
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
.blue-bg {
  position: relative;
  background-image: url("../assets/home/shining-blue.svg");
  // lightgray 0% 0% / 36.23046875px 36.23046875px repeat, #0033ea;
  background-color: $primary;
  background-repeat: repeat;
  border-radius: 12px 12px 0px 0px;
  margin: 4px 4px 0 !important;
}
.closex {
  cursor: pointer;
  position: absolute;
  right: 2%;
  top: 4%;
  color: $primary;
  width: 32px;
  height: 32px;
  background: $white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.white-text {
  width: 532px;
  margin: auto;
  h1 {
    padding-top: 49px;
    color: $white;
    text-align: center;
    font-family: Lato;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
    margin-bottom: 16px;
  }
  h5 {
    color: $white;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 16px;
  }
  button {
    width: 186px;
    height: 56px;
    border-radius: 30px;
    background: $shelta-yellow;
    color: $shelta-black;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    text-transform: uppercase;
    margin-bottom: 49px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.bottom-footer {
  margin-top: 32px;
  padding: 0 16px 40px;
  h6 {
    text-align: left;
    color: $shelta-gray;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 16px;
  }
}
.footer-text {
  h5 {
    text-align: left;
    color: $shelta-gray;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-content: center;
    img {
      padding-left: 16px;
    }
  }
  p {
    color: $shelta-gray2;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
}

@media only screen and (max-width: 599px) {
  .white-text {
    width: 100%;
    h1 {
      font-size: 36px;
      padding-left: 6px;
      padding-right: 6px;
    }
    h5 {
      padding: 0 23px;
    }
  }
  .footer-text {
    margin-bottom: 24px;
  }
}
</style>
